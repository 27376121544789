<template>
  <div id="app">
    <div v-if="!this.$route.meta.author" class="readuser">
      <div class="header" v-if="this.$route.meta.top">
        <div class="typerea">
          <div>
            <img @click="jump(0)" src="./images/logo.png" alt="">
            <div class="tag">
              <div class="tags" @click="jump(1)">
                <img src="./images/browse.svg" alt="">
                <span>{{ $t("index.oeuvre") }}</span>
              </div>
              <div class="tags" @click="jump(2)">
                <img src="./images/rank.svg" alt="">
                <span>{{ $t("index.ranking") }}</span>
              </div>
              <div class="tags" @click="jump(3)">
                <img src="./images/create.svg" alt="">
                <span>{{ $t("index.authorzone") }}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="search" @click="getInput">
              <el-input placeholder="Search" :class="{ inputbig: inputFlag }" ref="name" @blur="leaveInput"
                @change="jumpclassify" prefix-icon="el-icon-search" v-model="input">
              </el-input>
            </div>
            <div v-if="!isLoggedIn" @click="jumplogin" class="loginbut">Sign in</div>
            <div v-else class="tou">
              <img src="./images/book-tou.png" @click="jumpuser" alt="">
              <div class="userpopup">
                <div class="jiao"></div>
                <div class="usermess" @click="jumpuser">
                  <img src="./images/book-tou.png" alt="">
                  <div>
                    <span>{{ UserInfo.nickName }}</span>
                    <i>ID:{{ UserInfo.id }}</i>
                  </div>
                </div>
                <div class="getmore">
                  <div class="money">
                    <span class="r"></span>0
                  </div>
                  <div class="button">GET MORE</div>
                </div>
                <div class="link">
                  <p>Notification<em>23</em></p>
                  <p @click="jumplibrary">My library</p>
                  <p>Purchase record</p>
                </div>
                <div class="outlogin" @click="outlogin">Log out</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
      <div class="bottom" v-if="this.$route.meta.buttom">
        <div class="tit">
          <img src="@/images/login-logo.svg" alt="">
          <div class="tag">
            <span>About us</span>
            <span>Contact us</span>
            <span>Help center</span>
            <span>Notice</span>
          </div>
        </div>
        <div class="con">
          <div class="apple">
            <img src="./images/apple.svg" alt="">
            <div class="store">
              <span>Download on the</span>
              <i>App Store</i>
            </div>
          </div>
          <img src="./images/Goodle-logo.svg" alt="">
        </div>
        <div class="bot">
          <div class="typerea">
            <span>Copyright @rosettanovel 2024</span>
            <span><i @click="jumppolicy(1)">Terms of Service</i>·<i @click="jumppolicy(2)">Privacy Policy</i>·<i
                @click="jumppolicy(3)">Cookie
                Policy</i></span>
          </div>
        </div>
      </div>
    </div>
    <authorhome v-if="this.$route.meta.author"></authorhome>
  </div>
</template>

<script>
import authorhome from "@/views/AuthorCenter/home.vue"
export default {
  name: 'App',
  components: {
    authorhome,
  },
  data() {
    return {
      languageValue: '',
      languageOptions: [],
      inputFlag: false,
      input: this.$store.state.search
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    UserInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {
  },
  methods: {
    // 失去获取焦点input改变
    leaveInput() {
      this.inputFlag = false;
    },
    getInput() {
      this.$refs.name.focus();
      this.inputFlag = true;
    },
    jumpuser() {
      this.$router.push('/user');
    },
    jump(val) {
      if (val == 0) {
        this.$router.push('/index');
      } else if (val == 1) {
        this.$router.push('/classify');
      } else if (val == 2) {
        this.$router.push('/rank');
      } else {
        this.$router.push('/authorhome');
      }
    },
    jumpclassify(value) {
      this.$store.commit('updateSearch', value)
      this.$router.push('/classify');
    },
    jumplogin() {
      this.$router.push('/login');
    },
    jumplibrary() {
      this.$router.push('/library');
    },
    outlogin() {
      localStorage.removeItem('token');
      this.$store.dispatch('login');
      this.$message({
        message: 'Log out successfully',
        type: 'success',
        duration: 1000,
      });
    },
    jumppolicy(value) {
      if (value == 1) {
        this.$router.push('termsService')
      } else if (value == 2) {
        this.$router.push('provacyPolicy')
      } else if (value == 3) {
        // this.$router.push('/policy/3')
      }
    }
  },
  created() {
    if (!localStorage.getItem('fontsize')) {
      localStorage.setItem("fontsize", 18);
    }
    this.$store.dispatch('login');
  },
  mounted() {
  },
  updated() {
  },
  // 组件销毁前
  beforeDestroy() {
  }
}
</script>

<style lang="less">
#app {
  font-size: 15px;
  font-family: "";

  i,
  em {
    font-style: normal;
  }

  .header {
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #eee;
    position: fixed;
    justify-content: center;
    display: flex;
    z-index: 99;
    font-family: Inter;

    .typerea {
      width: 1040px;
      margin: 0 auto;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      >div {
        display: flex;
        align-items: center;

        >img {
          width: 26px;
          height: 35px;
          margin-left: 5px;
          cursor: pointer;
        }

        .tag {
          display: flex;

          .tags {
            display: flex;
            align-items: center;
            margin-left: 53px;
            cursor: pointer;
            font-size: 15px;

            img {
              width: 18px;
              height: 18px;
              margin-right: 10px;
            }
          }

          .tags:nth-child(1) {
            margin-left: 35px;
          }

        }

        .search {
          width: 240px;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 16px;

          .el-input {
            width: 36px;
            border-radius: 20px;
            overflow: hidden;
            transition: all 0.3s;

            .el-input__icon {
              line-height: 36px;
            }

            input {
              height: 34px;
              border: none;
              color: white;
              background: rgba(30, 30, 30, 0.10);
            }

            .el-input__prefix {
              color: white
            }

            ::-webkit-input-placeholder {
              color: white;
            }
          }

          .inputbig {
            width: 100%;
          }
        }

        .loginbut {
          display: flex;
          width: 84px;
          height: 30px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          color: white;
          font-size: 12px;
          background: #008CD6;
          cursor: pointer;
        }

        .tou {
          width: 34px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          cursor: pointer;

          &:hover .userpopup {
            display: block;
          }

          img {
            width: 100%;
            height: 34px;
            border-radius: 50%;
          }

          .userpopup {
            position: absolute;
            width: 262px;
            height: 350px;
            border-radius: 10px;
            border: 1px solid #D9D9D9;
            background-color: white;
            top: 55px;
            right: -4px;
            display: none;
            box-sizing: border-box;
            padding: 31px 30px 27px 30px;
            z-index: 99;

            .jiao {
              position: absolute;
              border: 7px solid transparent;
              border-bottom-color: #888;
              border-radius: 2px;
              top: -14px;
              right: 10px;
            }

            .usermess {
              width: 100%;
              height: 48px;
              font-family: "PingFang SC";
              display: flex;

              img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-right: 14px;
              }

              div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 138px;

                span {
                  font-size: 16px;
                  text-decoration: underline;
                  /* 1.让文本在一行展示 */
                  white-space: nowrap;
                  /* 2.设置溢出隐藏 */
                  overflow: hidden;
                  /* 3.超出文字变为省略号 */
                  text-overflow: ellipsis;
                }

                i {
                  font-size: 14px;
                  /* 1.让文本在一行展示 */
                  white-space: nowrap;
                  /* 2.设置溢出隐藏 */
                  overflow: hidden;
                  /* 3.超出文字变为省略号 */
                  text-overflow: ellipsis;
                }
              }
            }

            .getmore {
              height: 28px;
              width: 100%;
              display: flex;
              margin: 27px 0;
              justify-content: space-between;

              .money {
                display: flex;
                align-items: center;
                font-size: 16px;

                .r {
                  display: flex;
                  width: 28px;
                  height: 28px;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  border: 0.737px solid #FFF000;
                  background: url("./images/logo.png") #E64716 50% / cover no-repeat;
                  background-size: 14px 17.5px;
                  margin-right: 17px
                }
              }

              .button {
                display: flex;
                width: 96px;
                height: 26px;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                background: linear-gradient(96deg, #2E5AF7 4.43%, #1A8DC4 52.67%, #09C984 99.96%);
                font-size: 12px;
                color: white;
              }
            }

            .link {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 120px;
              height: 94px;

              p {
                width: 100%;
                height: 18px;
                font-size: 14px;
                line-height: 18px;
                margin: 0;
                position: relative;

                em {
                  position: absolute;
                  top: -6px;
                  left: 80px;
                  font-size: 10px;
                  color: #E64716;
                }
              }
            }

            .outlogin {
              display: flex;
              width: 96px;
              height: 26px;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              background-color: #6F8CC7;
              font-size: 12px;
              color: white;
              position: absolute;
              bottom: 27px;
              right: 30px;
            }
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 190px;
    background-color: #F5F6FB;
    padding-top: 36px;
    display: flex;
    flex-direction: column;

    .tit {
      width: 1080px;
      height: 45px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: end;

      img {
        width: 236px;
        height: 100%;
      }

      .tag {
        width: 700px;
        height: 28px;
        display: flex;
        justify-content: space-between;
        font-family: "PingFang SC";
        font-size: 15px;
        font-style: normal;
        color: #333;
        font-weight: 400;
      }
    }

    .con {
      width: 1080px;
      margin: 0 auto;
      display: flex;
      margin-top: 30px;

      .apple {
        box-sizing: border-box;
        width: 148px;
        height: 44px;
        display: flex;
        justify-content: space-between;
        margin-right: 18px;
        padding: 4.5px 14px 4.5px 8px;
        background-image: url(./images/apple-bg.png);

        img {
          width: 35px;
          height: 35px;
        }

        .store {
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          span {
            font-family: "PingFang SC";
            font-size: 9.62px;
            font-weight: 300;
          }

          i {
            font-family: "PingFang SC";
            font-size: 17.76px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }

      img {
        width: 148px;
        height: 44px;
      }
    }

    .bot {
      margin-top: 36px;
      border-top: 1px solid #CCC;
      flex: 1;

      .typerea {
        width: 1080px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "PingFang SC";
        font-size: 12px;
        font-weight: 400;
        color: #646464;

        i {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
