import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        redirect: "/index"
    }, {
        path: "/login",
        meta: {
            top: false,
            buttom: false,
        },
        component: () => import("@/views/login.vue")
    }, {
        path: "/enrolment",
        meta: {
            top: false,
            buttom: false,
        },
        component: () => import("@/views/enrolment.vue")
    }, {
        path: "/resetpass",
        meta: {
            top: false,
            buttom: false,
        },
        component: () => import("@/views/resetpass.vue")
    }, {
        path: "/index",
        meta: {
            top: true,
            buttom: true,
        },
        component: () => import("@/views/index.vue")
    }, {
        path: "/provacyPolicy",
        meta: {
            top: true,
            buttom: true,
        },
        component: () => import("@/views/others/privacyPolicy.vue")
    }, {
        path: "/termsService",
        meta: {
            top: true,
            buttom: true,
        },
        component: () => import("@/views/others/termsService.vue")
    }, {
        path: "/newsdetail/:id",
        name: "newsdetail",
        meta: {
            top: true,
            buttom: true,
        },
        component: () => import("@/views/others/newsdetail.vue")
    }, {
        path: "/paysuccess",
        meta: {
            top: false,
            buttom: false,
        },
        component: () => import("@/views/others/paysuccess.vue")
    }, {
        path: "/payerror",
        meta: {
            top: false,
            buttom: false,
        },
        component: () => import("@/views/others/payerror.vue")
    }, {
        path: "/detail/:id",
        name: "details",
        meta: {
            top: true,
            buttom: true,
        },
        component: () => import("@/views/details/details.vue")
    }, {
        path: "/reader",
        meta: {
            top: false,
            buttom: false,
        },
        component: () => import("@/views/details/read.vue")
    },
    {
        path: "/library",
        meta: {
            top: true,
            buttom: false,
            requiresAuth: true
        },
        component: () => import("@/views/library/library.vue")
    }, {
        path: "/user",
        meta: {
            top: true,
            buttom: false,
            requiresAuth: true
        },
        component: () => import("@/views/user/user.vue")
    }, {
        path: "/userSet",
        meta: {
            top: true,
            buttom: false,
            requiresAuth: true
        },
        component: () => import("@/views/user/userSet.vue")
    },
    {
        path: "/classify",
        meta: {
            top: true,
            buttom: false,
        },
        component: () => import("@/views/classify/classify.vue")
    }, {
        path: "/rank",
        meta: {
            top: true,
            buttom: true,
        },
        component: () => import("@/views/Rank/rank.vue")
    },
    {
        path: "/author/editor",
        name: "detail",
        meta: {
        },
        component: () => import("@/views/Author/BookEditor.vue")
    }, {
        path: "/authorhome",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/homepage.vue")
    }, {
        path: "/author/authorSet",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/authorSet.vue")
    }, {
        path: "/author/bankInfo",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/bankInfo.vue")
    }, {
        path: "/author/Newcontent",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/content.vue")
    }, {
        path: "/author/Income",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/income.vue")
    }, {
        path: "/author/Dshboard",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/dshboard.vue")
    }, {
        path: "/author/OpenCourses",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/openCourses.vue")
    }, {
        path: "/author/WorkPlatform",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/workplattab/workbook.vue")
    }, {
        path: "/author/bookset",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/workplattab/bookset.vue")
    }, {
        path: "/author/workdetail",
        meta: {
            requiresAuth: true,
            author: true
        },
        beforeRouteEnter(to, from, next) {
            // 在路由独享守卫后调用 不！能！获取组件实例 `this`，组件实例还没被创建
            if (!store.state.isLoggedIn) {
                alert('请先登录')
                next({ path: '/login' })
            } else {
                next()
            }
        },
        component: () => import("@/views/AuthorCenter/tabpage/workplattab/workdetail.vue")
    }
]

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
}
const router = new VueRouter({
    routes,
    scrollBehavior
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 检查用户是否登录，这里假设有一个isAuthenticated函数  
        if (!localStorage.getItem('token')) {
            // 用户未登录，保存当前路由  
            next({
                path: '/login',
                query: { redirect: to.fullPath } // 也可以将路由信息作为查询参数传递  
            });
        } else {
            next(); // 确保一定要调用 next()  
        }
    } else {
        next(); // 确保一定要调用 next()  
    }
});
export default router;