import openApiHttp from "@/utils/request2"
export let getCode = () => {
    return openApiHttp({
        url: "/user/userInfo"
    }, true)
}
export let addFeedBack = (content) => {
    return openApiHttp({
        url: "/user/addFeedBack",
        method: "post",
        data: {
            content,
        },
    }, true)
}
export let updateimg = (file) => {
    return openApiHttp({
        url: "/file/picUpload",
        method: "post",
        headers: { 'content-type': 'multipart/form-data' },
        data: {
            file,
        }
    }, true)
}
export let updateUserInfo = (data) => {
    return openApiHttp({
        url: "/user/updateUserInfo",
        method: "post",
        data,
    }, true)
}
export let dianzan = (data) => {
    return openApiHttp({
        url: "/like",
        method: "post",
        data,
    }, true)
}
export let getzan = (params) => {
    return openApiHttp({
        url: "/like",
        method: "get",
        params,
    }, true)
}