import openApiHttp from "@/utils/request2"
export let newlist = (params) => {
    return openApiHttp({
        url: "/news/listByPage",
        params,
    }, true)
}
export let authorregister = (data) => {
    return openApiHttp({
        url: "/author/register",
        method: "post",
        data,
    }, true)
}
export let replylist = (params) => {
    return openApiHttp({
        url: "/author/listBookCommentByPage",
        params,
    }, true)
}
export let updateAuthor = (data) => {
    return openApiHttp({
        url: "/author/updateAuthor",
        method: "post",
        data,
    }, true)
}
//发布小说
export let createbook = (data) => {
    return openApiHttp({
        url: "/author/addBook",
        method: "post",
        data,
    }, true)
}
//作者书架
export let getbooklist = (params) => {
    return openApiHttp({
        url: "/author/listBookByPage",
        params,
    }, true)
}