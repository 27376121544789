<!--  -->
<template>
    <div class='authorhome'>
        <img class="zhuye" @click="jumpindex" src="../../images/zhuyeicon.svg" alt="">
        <div class="tit">“Let each book fnd its readers”</div>
        <div class="bot">Copyright @rosettanovel 2024</div>
        <div class="left" :class="{ leftchange: !iconflag }">
            <i @click="iconchange" class="el-icon-d-arrow-left" :class="{ icon: !iconflag }"></i>
            <div v-show="iconflag" class="logo" @click="tabpage('authorhome')">
                <img src="../../images/logo.png" alt="">R copilot.
            </div>
            <div class="username" :class="{ tabactive: userflag, usernamesmall: !iconflag }" @click="tabpage('user')">
                <em v-show="iconflag">{{ this.$store.state.userInfo.nickName }}</em>
                <img v-show="!iconflag" src="@/images/tou-.png" alt="">
                <div class="popout">
                    <img src="../../images/popout.svg" alt="">
                    <div class="con">
                        <span @click.stop="workplat(index)" v-for="(item, index) in poputarr"
                            :class="{ tagactive: path == '/author/authorSet' && item.con == 'set up' ? true : false }">{{
                                item.con }}</span>
                        <img src="@/images/threecolor.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="tabtag">
                <div class="tag" :class="{ tabactive: item.flag }" @click="tabpage(item.con)" v-for="item in tabtag">
                    <img :src="item.url" alt=""><span v-show="iconflag">{{ item.con }}</span>
                </div>
            </div>
        </div>
        <div class="right" :class="{ rightchange: !iconflag }">
            <router-view class="right-con"></router-view>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { authorregister } from "@/api/authorcenter/index"
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {
    },
    data() {
        //这里存放数据 
        return {
            tabtag: [{
                url: require("@/images/dshboard.svg"),
                con: 'Dshboard',
                flag: false
            }, {
                url: require("@/images/work-platform.svg"),
                con: 'Work Platform',
                flag: false
            }, {
                url: require("@/images/income.svg"),
                con: 'Income',
                flag: false
            },
            {
                url: require("@/images/open-courses.svg"),
                con: 'Open Courses',
                flag: false
            }
            ],
            poputarr: [{
                con: 'set up',
            }, {
                con: 'sign out',
            }],
            poputflag: false,
            userflag: false,
            iconflag: true
        };
    },
    //计算属性 类似于data概念
    computed: {
        path() {
            return this.$route.fullPath
        }
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        jumpindex() {
            this.$router.push('/index')
        },
        tabpage(con) {
            this.tabtag.forEach((item) => {
                if (con == item.con) {
                    item.flag = true;
                    this.$router.push("/author/" + con.split(' ').join(''));
                } else {
                    if (con == "authorhome") {
                        this.$router.push("/authorhome");
                    }
                    if (con != 'user') {
                        this.userflag = false;
                        item.flag = false;
                    }
                }
            })
        },
        workplat(index) {
            this.userflag = true
            this.tabtag.forEach((item) => {
                item.flag = false
            })
            if (index == 0) {
                this.$router.push('/author/authorSet')
            } else {
                this.$message({
                    message: '退出成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                        this.$router.push('/index')
                        localStorage.removeItem('token');
                        this.$store.dispatch('login');
                    }
                })
            }
        },
        iconchange() {
            this.iconflag = !this.iconflag
        }
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // if (this.$store.state.userInfo.userInfoIdentity == '1') {
        authorregister({
            email: this.$store.state.userInfo.email
        }).then(res => {
        })
        // }
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() {
    }, //生命周期 - 销毁之前
    destroyed() {
    }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
.authorhome {
    width: 100%;
    background: #C9C0BB;
    height: 100vh;
    box-sizing: border-box;
    padding: 40px 0 30px 0;
    overflow: hidden;
    position: relative;
    display: flex;

    .zhuye {
        position: absolute;
        width: 130px;
        height: 18px;
        top: 15px;
        left: 18px;
        cursor: pointer;
    }

    .tit {
        position: absolute;
        color: #FFF;
        font-family: "Lucida Calligraphy";
        font-size: 22px;
        font-style: italic;
        top: 8px;
        right: 80px;
    }

    .bot {
        position: absolute;
        font-size: 12px;
        bottom: 6px;
        left: 12%;
    }


    .left,
    .right {
        background-color: white;
        border-radius: 4px;
        height: calc(100vh - 70px);
        padding: 24px 16px;
        padding-right: 0;
        box-sizing: border-box;
    }

    .left {
        width: 11.5%;
        font-family: "PingFang SC";
        font-size: 14px;
        border-radius: 0px 10px 10px 0px;
        position: relative;
        transition: all 1s;

        .el-icon-d-arrow-left {
            position: absolute;
            right: 12px;
            top: 12px;
            font-size: 18px;
            color: #A69890;
            cursor: pointer;
            transition: all 0.3s;
        }

        .icon {
            transform: rotate(180deg);
        }

        .logo {
            width: 100%;
            height: 38px;
            cursor: pointer;
            overflow: hidden;

            img {
                width: 26.5px;
                height: 35px;
                margin-left: 5px;
                margin-right: 7px;
                vertical-align: bottom;
            }
        }

        .username {
            padding-left: 12px;
            margin: 40px 0;
            width: 100%;
            height: 38px;
            box-sizing: border-box;
            line-height: 38px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;

            em {
                display: block;
                // overflow: hidden;
                white-space: nowrap;
                /* 2.设置溢出隐藏 */
                overflow: hidden;
                /* 3.超出文字变为省略号 */
                text-overflow: ellipsis;
            }

            >img {
                width: 32px;
                height: 32px;
            }

            &:hover {
                background: #F4F5F9;
                border-radius: 19px 0 0 19px;
                color: #333;
            }

            &:hover .popout {
                display: block;
            }

            .popout {
                display: none;
                width: 124px;
                height: 78px;
                position: absolute;
                top: -5px;
                right: -123px;
                box-sizing: border-box;
                z-index: 111;

                .con {
                    position: absolute;
                    font-size: 12px;
                    top: 0;
                    left: 2px;
                    padding: 10px 20px;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;

                    span {
                        padding: 6px 0;
                        border-radius: 15px 0 0 15px;
                        padding-left: 15px;
                        line-height: 16px;
                        color: #A69890;

                        &:hover {
                            color: #333;
                        }
                    }

                    .tagactive {
                        color: #333;
                        background-color: #F4F5F9;
                    }

                    img {
                        position: absolute;
                        bottom: 8px;
                        right: 8px;
                    }
                }
            }
        }

        .usernamesmall {
            margin: 59px 0;
            padding: 0;
        }

        .tabactive {
            background: #F4F5F9;
            border-radius: 19px 0 0 19px;
            color: #333;
        }

        .tabtag {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .tag {
                height: 38px;
                display: flex;
                align-items: center;
                color: #A69890;
                cursor: pointer;
                overflow: hidden;

                &:hover {
                    background: #F4F5F9;
                    border-radius: 19px 0 0 19px;
                    color: #333;
                }

                img {
                    margin-left: 12px;
                    margin-right: 6px
                }
            }
        }
    }

    .leftchange {
        width: 4.45%;
    }

    .right {
        width: 87%;
        padding: 20px;
        margin: 0 20px;
        box-sizing: border-box;
        border-radius: 10px;
        transition: all 1s;

        .right-con {
            border-radius: 10px;
            border: 1px solid #D6D0CC;
            width: 100%;
            height: 100%;

            &::-webkit-scrollbar {
                width: 6px;
                /* 滚动条宽度 */
                height: 10px;
                /* 垂直滚动条的宽度 */
            }

            /* 滚动条滑块 */
            &::-webkit-scrollbar-thumb {
                background-color: #FCD000;
                /* 滑块颜色 */
                border-radius: 20px;
                /* 圆角 */
            }

            /* 滚动条轨道 */
            &::-webkit-scrollbar-track {
                background-color: #E7E4E2;
                /* 轨道颜色 */
                border-radius: 20px;
            }

        }

        .row-bg {
            padding: 10px 0;
            background-color: #f9fafc;
        }

    }

    .rightchange {
        width: 93%;
    }
}
</style>