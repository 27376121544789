const en = {
    login: {
        title: 'Welcome home,Dear User',
        btn: "Sign in",
        submitbtn: "Submit",
        password: "Password",
        remember: "Remember password",
        username: "Username",
        signbtn: "Sign up",
        signup: "Sign up now",
        noregister: "Don't have an account?",
        yetregister: " Already have an account?",
        auto: "Keep me logged in",
        phone: " Phone",
        phonetip: "Please enter your phone number",
        passtip: 'Please enter password:" 6 - 20 letters/ num',
        email: "Please enter the email",
        getemailcode: "Get verification code",
        emailcode: "Please enter the email verification c",
        code: "Please enter the verification code",
    },
    index: {
        description: "webnovel, internet novel, wattpad, amazon novel",
        index: "Home page",
        oeuvre: "Browse",
        ranking: "Rankings",
        rankinglist: "Ranking list",
        mybook: "library",
        recharge: "Recharge",
        authorzone: "Create",
        serchplacehold: "Title, Author, Keywords",
        weekpromotion: "Weekly Featured",
        recommend: "Novel Radar",
        click: "Top Fanfic Books",
        premium: "Peak Ranking",
        likerank: "Favorite List",
        latest: "Most Recently Updated",
        comment: "Comment list",
        finishedrank: "Finished darft list",
        copyright: "Copyright",
        limittime: "Limited time free"
    },
    book: {
        num: "Num",
        name: "Book Name",
        type: "Genre",
        chapter: "Latest Release",
        author: "Auther",
        updatetime: "Update Time",
        channel: "Works Channel",
        read: "READ",
        addto: "ADD TO LIBRARY",
        contentstab: "Table of Contents",
        reviews: "Reviews",
        addreviews: "Add a reviews",
        post: "Post",
        reviewscontents: "Say something",
        status: "Status",
        ongoing: "Ongoing",
        completed: "Completed",
        unfold: "Unfold",
        fold: "Fold",
        words: "Words",
        contracted: "Contracted Authors",
        alsoloke: "You May Also Like",
    },
    borwser: {
        channel: "Channel",
        male: "Male Lead",
        female: "Female Lead",
        genre: "Genre",
        genre_branch: "All, Eastern, Fant",
        isprice: "Charge or not",
        price: "Price",
        free: "free",
        charge: "charge",
        status: "Status",
        status_branch: "All",
        isfinish: "Is it completed",
        words: "Words",
        words_branch: "< 300K",
        words_branch1: "300K- 500K",
        words_branch2: "500k- 1M",
        words_branch3: "> 1M",
        time: " Update",
        time_branch: " 3days",
        time_branch1: " 7days",
        time_branch2: " 15days",
        time_branch3: " 30days",
        sort: " Sort By",
        sort_branch: " Time updated",
        sort_branch1: " Popular",
        sort_branch2: " Rating",
        sort_branch3: " Recommended",
    },
    author: {
        title: "I am an online novel translator and I would like to register as a contracted author for RosettaNovel:",
        invite: "Invitation code",
        invitetip: "The invitation code assigned to the author by RosettaNovel",
        penName: "Pen name",
        penNameTip: "Chinese and English with a length of 2 to 8 digits",
        phoneTip: "The editor of RosettaNovel will contact you through this number",
        chatAccount: "QQ or WeChat",
        email: "Email",
        emailTip: "Chinese, English, and numbers ranging from 2 to 15 digits in length",
        role: "Actress",
        role1: "Male frequency",
        role2: "Female frequency",
        roletip: "Please choose the direction of your work",
        registebtn: "Start your author career immediately",
    }
}
export default en;
