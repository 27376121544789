const zh = {
    login: {
        title: '欢迎来到主页，亲爱的用户',
        btn: '登录',
        submitbtn: '提交',
        password: '密码',
        remember: '记住密码',
        username: '用户名',
        signbtn: '注册',
        signup: '立即注册',
        noregister: '还没有注册账号 ?',
        yetregister: '已经注册账号？',
        auto: '下次自动登录',
        phone: '手机号码',
        phonetip: '请输入您的手机号码',
        passtip: '请输入密码：6-20位字母/ 数字',
        email: '请输入电子邮箱',
        getemailcode: '获取验证码',
        emailcode: '请输入邮箱验证码',
        code: '请输入验证码',
    },
    index: {
        index: "首页",
        oeuvre: "浏览",
        ranking: "排行",
        rankinglist: "排行榜",
        mybook: "书架",
        recharge: "充值",
        authorzone: "创作",
        serchplacehold: "书名、作者、关键字",
        weekpromotion: "本周强推",
        recommend: "热门推荐",
        click: "书籍排行榜",
        premium: "巅峰榜",
        likerank: "喜欢榜",
        latest: "最近更新",
        comment: "评论榜单",
        finishedrank: "完结榜",
        copyright: "版权所有",
        limittime: "限时免费"
    },
    book: {
        num: "序号",
        name: "书名",
        type: "类别",
        chapter: "最新章节",
        author: "作者",
        updatetime: "更新时间",
        channel: "作品频道",
        read: "点击阅读",
        addto: "加入书架",
        contentstab: "全部目录",
        reviews: "作品评论",
        addreviews: "发表评论",
        post: "发表",
        reviewscontents: "我来说两句",
        status: "状态",
        ongoing: "连载中",
        completed: "已完结",
        unfold: "展开",
        fold: "收起",
        words: "总字数",
        contracted: "签约作家",
        alsoloke: "猜你喜欢",
    },
    borwser: {
        channel: "作品频道",
        male: "男频",
        female: "女频",
        genre: "作品分类",
        genre_branch: "不限，玄幻，架空世界，恐怖，武侠，现实，ACG，都市，游戏，科幻，电子竞技，军事，历史",
        isprice: "是否收费",
        price: "收费",
        free: "免费作品",
        charge: "付费作品",
        status: "作品状态",
        status_branch: "不限",
        isfinish: "是否完结",
        words: "作品字数",
        words_branch: "30万字以下",
        words_branch1: "30 - 50万字",
        words_branch2: "50 - 100万字",
        words_branch3: "100万字以上",
        time: "更新时间",
        time_branch: "三日内",
        time_branch1: "七日内",
        time_branch2: "半月内",
        time_branch3: "一月内",
        sort: " 排序方式",
        sort_branch: "更新时间",
        sort_branch1: "最热",
        sort_branch2: "评分",
        sort_branch3: "推荐",
    },
    author: {
        title: "我是网络小说翻译写手，我要注册为RosettaNovel签约作者：",
        invite: "邀请码",
        invitetip: "RosettaNovel分配给作者的邀请码",
        penName: "作者笔名",
        penNameTip: "长度为2到8位的中英文",
        phoneTip: "RosettaNovel的编辑会通过这个号码与您联系",
        chatAccount: "QQ或微信",
        email: "电子邮箱",
        emailTip: "长度为2到15位的中英文，数字",
        role: "男女主角",
        role1: "男性主角选男频",
        role2: "女性主角选女频",
        roletip: "请选择作品方向",
        registebtn: "立即开始您的作者生涯",
    },
}
export default zh;
