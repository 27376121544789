import axios from "axios";
import router from "@/router";
import { Message } from 'element-ui';
const openApiHttp = (config, flag) => {
    const instance = axios.create({
        baseURL: process.env.VUE_APP_API,
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    });
    // 添加请求拦截器  
    instance.interceptors.request.use(
        config => {
            // 在发送请求之前做些什么  
            const token = localStorage.getItem("token");
            if (token) {
                config.headers["Authorization"] = token; // 假设你的后端期望这样的格式  
            }
            return config;
        },
        error => {
            // 对请求错误做些什么  
            return Promise.reject(error);
        }
    );

    // 添加响应拦截器  
    instance.interceptors.response.use(
        response => {
            // 对响应数据做点什么
            // 例如，你可以在这里处理全局的错误码 
            // console.log(response.status);
            if (response.status === 200) {
                if (response.data.code == 1001) {
                    Message({
                        message: '登录过期或失效，即将跳转到登录页面',
                        type: 'error',
                        duration: 2000,
                        onClose: () => {
                            //此处写提示关闭后需要执行的函数
                            router.replace('/login')
                        }
                    })
                } else {
                    return response.data;
                }// 直接返回 data  
            }
        },
        error => {
            if ([401, 403].includes(error.status)) {
                // 无访问权限，可能情况有
                // 401:没传token或者使用伪造的token
                // 403:访客越权访问用户服务
                router.replace('/login')
            }
            // 超出 2xx 范围的状态码都会触发该函数。  
            // 对响应错误做点什么  
            // 例如，你可以在这里统一处理网络错误或服务器错误  
            return Promise.reject(error);
        }
    );
    if (flag) {
        // 接口传参来判断需不需要跳转登录
        if (localStorage.getItem("token")) {
        } else {
            Message({
                message: '登录过期或失效，即将跳转到登录页面',
                type: 'error',
                duration: 2000,
                onClose: () => {
                    //此处写提示关闭后需要执行的函数
                    router.replace('/login')
                }
            })
        }
    }
    return instance.request(config);
};

export default openApiHttp;