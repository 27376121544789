import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
Vue.config.productionTip = false
import "./assets/css/every.css"
import "amfe-flexible"
import { i18n } from './i18n/index.js';
import store from './store'
import Vant from "vant"
import "vant/lib/index.css"
import "@/assets/icon/iconfont.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.imgurl1 = process.env.VUE_APP_IMGURL1
Vue.prototype.imgurl2 = process.env.VUE_APP_IMGURL2
Vue.use(Vant);
Vue.use(ElementUI);
import WS from '@/utils/websocket'
Vue.prototype.$ws = WS
import * as voicePromptFun from './utils/voicePrompt'
Vue.prototype.voicePrompt = voicePromptFun.voicePrompt  //语音提醒
const originalPush = VueRouter.prototype.push
// 重写原型上的push方法，统一处理错误信息
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
new Vue({
  router,
  store,
  i18n,
  // el: '#app',
  render: h => h(App),
}).$mount('#app')
