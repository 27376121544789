import Vue from "vue";
import Vuex from 'vuex'
import { getCode } from "@/api/user";
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        search: "",
        fontsize: localStorage.getItem("fontsize") || 18,
        fontfamily: 'Nunito Sans',
        night: false,
        eye: false,
        isLoggedIn: false,
        userInfo: null,
        wordcount: '',
        webSocketMsg: '',
        payAiflag: true,
    },
    mutations: {
        payAishow(state, flag) {
            if (flag) {
                state.payAiflag = true
            } else {
                state.payAiflag = false
            }
        },
        // webSocket消息推送
        SET_WS_MSG: (state, msg) => {
            state.webSocketMsg = msg
        },
        setLoginStatus(state, status) {
            state.isLoggedIn = status;
        },
        updateSearch: (state, input) => {
            state.search = input
        },
        fontsizeadd: (state) => {
            if (localStorage.getItem("fontsize") < 30) { // 假设最大字体大小为30px 
                localStorage.setItem("fontsize", Number(localStorage.getItem("fontsize")) + 2);
                state.fontsize = localStorage.getItem("fontsize");
            }
        },
        fontsizedec: (state) => {
            if (localStorage.getItem("fontsize") > 10) { // 假设最小字体大小为18px 
                localStorage.setItem("fontsize", Number(localStorage.getItem("fontsize")) - 2);
                state.fontsize = localStorage.getItem("fontsize");
            }
        },
        fontfamilychange(state, e) {
            state.fontfamily = e
        },
        patternTab(state, pattern) {
            if (pattern == 0) {
                state.night = false;
                state.eye = false;
            } else if (pattern == 1) {
                state.night = false;
                state.eye = true;
            } else {
                state.night = true;
                state.eye = false;
            }
        },
    },
    actions: {
        login({ state, commit, }) {
            const token = localStorage.getItem("token")
            if (token) {
                getCode().then(res => {
                    state.userInfo = res.data
                })
                commit('setLoginStatus', true);
            } else {
                commit('setLoginStatus', false);
            }
        },
    }
})
export default store